/* -------------------------------------------------------------------------------------------------
 * Profile Constants
 * -----------------------------------------------------------------------------------------------*/

export const EMPTY = ''

export const DEFAULTS = {
  AREA_UNIT: 'ha',
  DATE_PATTERN: 'dd/MM/yyyy',
  LANGUAGE: 'en',
  LENGTH_UNIT: 'meter',
  WEIGHT_UNIT: 'Kg',
  DENSITY_UNIT: 'Kg/l',
  AREA_DENSITY_UNIT: 'Kg/ha',
  VOLUME_UNIT: 'l',
  COUNTRY_ID: EMPTY,
  REGION_ID: EMPTY,
}
