import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { example } from './example/example'
import { preferencesSlice } from './preferences/slicer'
import { analysesSlice } from './analyses'
import { clientsSlice } from './clients/slicer'
import { notifications } from './notification/slicer'
import { permissionsSlice } from './permissions/slicer'
import { programsSlice } from './programs/slicer'

const store = configureStore({
  reducer: {
    message: example.reducer,
    analyses: analysesSlice.reducer,
    clients: clientsSlice.reducer,
    preferences: preferencesSlice.reducer,
    notifications: notifications.reducer,
    permissionsSlice: permissionsSlice.reducer,
    programsSlice: programsSlice.reducer,
  },
})

store.dispatch(
  example.actions.setMessage({
    message: 'Redux setup is completed!!!',
    status: 'active',
  }),
)

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default store
