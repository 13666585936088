import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ItemsPerPageOptions = 20 | 50 | 100

type PreferencesState = {
  itemsPerPage: ItemsPerPageOptions
  userInfoGroup: string
}

type PreferencesSlicer = {
  readonly setItemsPerPage: (state: PreferencesState, action: PayloadAction<ItemsPerPageOptions>) => void
  readonly setUserInfoGroup: (state: PreferencesState, action: PayloadAction<string>) => void
}

export const preferencesSlice = createSlice<PreferencesState, PreferencesSlicer>({
  name: 'preferences',
  initialState: {
    itemsPerPage: 20,
    userInfoGroup: '',
  },
  reducers: {
    setItemsPerPage: (state, action) => {
      localStorage.setItem('itemsPerPage', action.payload.toString())
      state.itemsPerPage = action.payload
    },
    setUserInfoGroup(state, action) {
      state.userInfoGroup = action.payload
    },
  },
})

export const { setItemsPerPage, setUserInfoGroup } = preferencesSlice.actions
