import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProgramRecommendation } from '../../graphql/api/types'

type ProgramsState = {
  showProgram: ProgramRecommendation
}

type ProgramsSlicer = {
  readonly setShowProgram: (state: ProgramsState, action: PayloadAction<ProgramRecommendation>) => void
}

export const programsSlice = createSlice<ProgramsState, ProgramsSlicer>({
  name: 'programs',
  initialState: {
    showProgram: {} as ProgramRecommendation,
  },
  reducers: {
    setShowProgram: (state, action) => {
      state.showProgram = action.payload
    },
  },
})

export const { setShowProgram } = programsSlice.actions
