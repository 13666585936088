import { css } from '@emotion/css'

export default css`
  @font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono'), url('/assets/fonts/IBMPlexMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Semibold'), url('/assets/fonts/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
`
