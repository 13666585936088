import { AnalyticsBrowser } from '@segment/analytics-next'
import { SegmentEventType } from '@segment/analytics-core/src/events/interfaces'

export const analytics = AnalyticsBrowser.load({
  writeKey: window.ENV.REACT_APP_GLOBAL_SEGMENT_ID,
})

export const analyticsForBoth = async (execute: SegmentEventType, eventName: string, params?: any) => {
  await AnalyticsBrowser.load({
    writeKey: window.ENV.REACT_APP_AMERICA_SEGMENT_ID,
  })[execute](eventName, params)
  await AnalyticsBrowser.load({
    writeKey: window.ENV.REACT_APP_GLOBAL_SEGMENT_ID,
  })[execute](eventName, params)
}
