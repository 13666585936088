import _ from 'lodash'
import { IdToken as Auth0User } from '@auth0/auth0-react'
import { createLoggedUser, User } from './types/user'
import { AuthenticationError } from './errors/AuthenticationError'

export const transformAuth0UserToUser = (user: Auth0User): User => {
  const namespace = 'https://yaraayra.com'
  const givenName = _.get(user, `${namespace}/givenName`, '')
  const familyName = _.get(user, `${namespace}/familyName`, '')
  const groupId = _.get(user, `${namespace}/groupId`, '')
  const email = _.get(user, 'email', '')
  const userName = getUserName(user, email, givenName, familyName)

  /**
   * As there could be cases where we do not know the type of the givenName and familyName,
   * it was necessary to type givenName and familyName as `any`
   */
  function getUserName(user: Auth0User, email: string, givenName?: any, familyName?: any) {
    const hasAuth = _.get(user, 'iss', '')?.includes('auth')
    const hasGivenName = !_.isEmpty(givenName)
    const hasFamilyName = !_.isEmpty(familyName)

    if (hasAuth && hasGivenName && hasFamilyName) {
      return `${givenName} ${familyName}`
    }

    return email
  }

  return createLoggedUser({
    givenName,
    familyName,
    email,
    groupId,
    username: userName,
    picture: _.get(user, 'picture', ''),
    token: _.get(user, '__raw', ''),
    sub: _.get(user, 'sub', ''),
  })
}

export const validateAuth0User = (user: Maybe<Auth0User>): Auth0User => {
  if (_.isNil(user)) {
    throw AuthenticationError.whenUserIsNotLogged()
  }

  return user
}
