import i18n, { InitOptions } from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { Monitoring } from './utils/monitoring'

let language = 'en'

const prefix = '/'

switch (window.navigator.language.slice(0, 2)) {
  case 'pt':
    language = 'pt-BR'
    break
  case 'es':
    language = 'es-AR'
    break
  case 'pl':
    language = 'pl'
    break
  case 'uk':
    language = 'uk'
    break
  default:
    language = 'en'
}

try {
  // Access to localStorage might throw an exception in private mode on iOS
  language = localStorage.getItem('language') || language
  // eslint-disable-next-line no-empty
} catch (e) {
  Monitoring.error(new Error(String(e)), {
    kind: 'language',
  })
}

const i18nInstance = i18n.createInstance()

i18nInstance
  .use(Backend)
  .use(initReactI18next)
  .on('languageChanged', function (language: string) {
    localStorage.setItem('language', language)
  })

export const i18nOptions = ({ version }: { version: string }): InitOptions => ({
  load: 'currentOnly',
  fallbackLng: 'en',
  keySeparator: false, // we do not use keys in form messages.welcome
  lng: language,
  saveMissing: true,
  missingKeyHandler: function () {
    // console.debug(`[i18n] - missing key`, { key, ns, languages, fallbackValue })
  },
  react: {
    bindI18n: 'loaded languageChanged languageChanging',
    bindI18nStore: 'added',
    useSuspense: true,
  },
  backend: {
    loadPath: `/locale/{{lng}}.json`,
    queryStringParams: { version },
    addPath: `${prefix}locale/add/{{lng}}/{{ns}}`,
    requestOptions: {
      cache: window.ENV.REACT_APP_ENV_NAME === 'production' ? 'default' : 'reload',
    },
  },
})

export default i18nInstance
