import { User } from '../../userprofile/types/user'
import { EventPayload, Monitoring } from './types'

const page = (name: string) => ineum('page', name)
const meta = (key: string, value: string) => ineum('meta', key, value)
const user = (user: User) => ineum('user', user.sub, user.username, user.email)
const trace = (traceId: string) => ineum('traceId', traceId)

const event = (name: string, payload: EventPayload) => {
  return ineum('reportEvent', name, {
    duration: new Date().getTime() - payload.start,
    componentStack: payload.component,
    error: payload.error,
    meta: payload.meta,
  })
}

const error = (error: Error, payload: Record<string, string | number | boolean>) =>
  ineum('reportError', error, {
    meta: payload,
  })

export const instana: Monitoring = {
  page,
  meta,
  event,
  user,
  error,
  trace,
}
