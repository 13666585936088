import _ from 'lodash'
import React from 'react'
import i18n, { i18nOptions } from '../i18n'

const I18nProvider: React.FC = ({ children }) => {
  const i18nConfig = i18nOptions({
    version: window.ENV.REACT_APP_VERSION,
  })

  React.useEffect(() => {
    i18n.init(i18nConfig).then(_.identity)
  }, [i18nConfig])

  return <React.Fragment>{children}</React.Fragment>
}

export default I18nProvider
