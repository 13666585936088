import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type PermissionsState = {
  canUserSee: boolean
}

type PermissionsSlicer = {
  readonly setCanUserRead: (state: PermissionsState, action: PayloadAction<boolean>) => void
}

export const permissionsSlice = createSlice<PermissionsState, PermissionsSlicer>({
  name: 'permissions',
  initialState: {
    canUserSee: false,
  },
  reducers: {
    setCanUserRead: (state, action) => {
      state.canUserSee = action.payload
    },
  },
})

export const { setCanUserRead } = permissionsSlice.actions
