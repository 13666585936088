import React from 'react'
import { Drawer, ThemeProvider } from '@material-ui/core'

import { DatePattern, UpdateUserPreferencesDTO } from './types/preferences'
import { SettingsContext } from './SettingsProvider'
import { SettingsForm } from './SettingsForm'

import themeV2 from '../../theme-v2'
import CloseButton from '../../components/Buttons/CloseButton'
import { UserPreferencesContext } from '../../contexts/preferences/UserPreferencesContext'
import { SIDEBAR_NAVIGATION_WIDTH } from '../../components/Navigation/constants'
import { useSetUserPreferencesMutation } from '../../graphql/gateway/queries/SetUserPreferences.generated'
import i18n from '../../i18n'
import { unsafeCoerce } from 'fp-ts/lib/function'

const SettingsSidebar = () => {
  const profile = React.useContext(UserPreferencesContext)
  const { open, toggleOpen } = React.useContext(SettingsContext)

  const onClose = React.useCallback(() => {
    toggleOpen()
  }, [toggleOpen])

  const [setUserPreferences, { loading }] = useSetUserPreferencesMutation({
    onCompleted: async ({ setUserPreferences }) => {
      const language = setUserPreferences.preferences?.language || 'en'
      await i18n.changeLanguage(language)
      onClose()
    },
  })

  const initial = React.useMemo(
    function (): UpdateUserPreferencesDTO {
      return {
        areaUnit: profile.area.name,
        defaultCountryId: profile.country.id,
        defaultRegionId: profile.region.id,
        lengthUnit: profile.length.name,
        massUnit: profile.weight.name,
        volumeUnit: profile.volume.name,
        language: profile.language || 'en',
        datePattern: unsafeCoerce<string, DatePattern>(profile.datePattern),
      }
    },
    [profile],
  )

  const onSettingsSaved = React.useCallback(
    async (dto: UpdateUserPreferencesDTO) => {
      await setUserPreferences({
        variables: {
          userId: profile.userId,
          preferences: {
            userId: profile.userId,
            countryId: dto.defaultCountryId,
            regionId: dto.defaultRegionId,
            areaUnit: dto.areaUnit,
            datePattern: dto.datePattern,
            language: dto.language,
            lengthUnit: dto.lengthUnit,
            massUnit: dto.massUnit,
            volumeUnit: dto.volumeUnit,
            showContactDetails: true,
          },
        },
      })
      window.location.reload()
    },
    [profile.userId, setUserPreferences],
  )

  const onFail = React.useCallback((errors) => {
    console.error('Validation failures', errors)
  }, [])

  return (
    <ThemeProvider theme={themeV2}>
      <Drawer
        open={open}
        style={{ marginLeft: SIDEBAR_NAVIGATION_WIDTH, zIndex: 70 }}
        BackdropProps={{ style: { marginLeft: SIDEBAR_NAVIGATION_WIDTH, zIndex: 10 } }}
        PaperProps={{ style: { marginLeft: SIDEBAR_NAVIGATION_WIDTH, zIndex: 10 } }}
        onClose={onClose}
      >
        <CloseButton onClick={onClose} />
        <SettingsForm initial={initial} loading={loading} onSave={onSettingsSaved} onFail={onFail} />
      </Drawer>
    </ThemeProvider>
  )
}

export default SettingsSidebar
