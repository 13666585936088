import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from '../routes'
import { UserPreferencesContext } from '../contexts/preferences/UserPreferencesContext'
import ProtectedRoute from '../components/ProtectedRoute'
import { LoadingCircular } from '../components/LoadingCircular'
import { canFromPermissions } from '../userprofile/canFromPermissions'
import { useFlags } from 'launchdarkly-react-client-sdk'

/* -------------------------------------------------------------------------------------------------
 * Lazy Imports
 * -----------------------------------------------------------------------------------------------*/

const Programs = React.lazy(() => import(/* webpackChunkName: "Programs", webpackPrefetch: true */ './Programs'))
const Analyses = React.lazy(() => import(/* webpackChunkName: "Analyses", webpackPrefetch: true */ './Analyses'))
const Combos = React.lazy(() => import(/* webpackChunkName: "Combos", webpackPrefetch: true */ './Combos'))
const Clients = React.lazy(() => import(/* webpackChunkName: "Clients", webpackPrefetch: true */ './Clients'))
const Users = React.lazy(() => import(/* webpackChunkName: "Users", webpackPrefetch: true */ './Users'))
const Backoffice = React.lazy(() => import(/* webpackChunkName: "BackOffice", webpackPrefetch: true */ './BackOffice'))

/* -------------------------------------------------------------------------------------------------
 * Routing
 * -----------------------------------------------------------------------------------------------*/
const Routing: React.FC = () => {
  const { permissions } = React.useContext(UserPreferencesContext)
  const { clientsMenu, fvcUpdatesMenu } = useFlags()

  const can = canFromPermissions(permissions)
  const canSeeUsers = can('USERS|USER_MANAGEMENT|READ_ALL')

  return (
    <React.Suspense fallback={<LoadingCircular />}>
      <Switch>
        <Route path={routes.analyses} component={Analyses} />
        <Route path={routes.combos} component={Combos} />
        <Route path={routes.programs} component={Programs} />
        <ProtectedRoute condition={clientsMenu} path={routes.clients} component={Clients} />
        <ProtectedRoute condition={canSeeUsers} path={routes.users} component={Users} />
        <ProtectedRoute condition={fvcUpdatesMenu} path={routes.backOffice} component={Backoffice} />
        <Redirect to={routes.programs} />
      </Switch>
    </React.Suspense>
  )
}

Routing.displayName = 'AppRouting_Internal'

export default Routing
