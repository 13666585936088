import React from 'react'
import { DEFAULTS } from '../../constants/profile'
import { REGULAR } from '../../userprofile/roles'
import { UserProfile } from '../../userprofile/types/preferences'
import { unsafeCoerce } from 'fp-ts/function'

const userPreferencesContext = React.createContext<UserProfile>({
  area: unsafeCoerce({
    name: DEFAULTS.AREA_UNIT,
  }),
  areaDensity: unsafeCoerce({
    name: DEFAULTS.AREA_DENSITY_UNIT,
  }),
  country: unsafeCoerce({}),
  datePattern: DEFAULTS.DATE_PATTERN,
  density: unsafeCoerce({
    name: DEFAULTS.DENSITY_UNIT,
  }),
  email: '',
  group: unsafeCoerce({}),
  isAdmin: false,
  isAuthorized: false,
  isBlocked: false,
  isOnboarded: false,

  language: DEFAULTS.LANGUAGE,
  length: unsafeCoerce({
    name: DEFAULTS.LENGTH_UNIT,
  }),
  name: '',
  permissions: [],
  phone: '',

  region: unsafeCoerce({}),
  role: REGULAR,
  shouldDisplaySidebarNav: false,

  shouldDisplaySignupRoutes: false,
  userId: '',

  volume: unsafeCoerce({
    name: DEFAULTS.LENGTH_UNIT,
  }),
  weight: unsafeCoerce({
    name: DEFAULTS.WEIGHT_UNIT,
  }),
})

userPreferencesContext.displayName = 'UserPreferencesContext'

export { userPreferencesContext as UserPreferencesContext }
