import { TFunction } from 'i18next'
import { slugTranslationKey } from './slugs'

interface RegionType {
  name?: Maybe<string> | null
}

export const getRegionTranslationKey = (name: string) => `regions.${slugTranslationKey(name)}`
export const getRegionName = (region: Maybe<RegionType> | undefined, t: TFunction): string => {
  if (!region) {
    return ''
  }

  const { name } = region

  if (!name) {
    return ''
  }

  return t(getRegionTranslationKey(name))
}
