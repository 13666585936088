import React from 'react'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { useAsync } from 'react-use'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { UserPreferencesContext } from '../preferences/UserPreferencesContext'
import { buildLaunchDarklyContext } from '../../userprofile/launchdarkly'

const LaunchDarklyProvider: React.FC = ({ children }) => {
  const profile = React.useContext(UserPreferencesContext)

  const { value } = useAsync(() =>
    asyncWithLDProvider({
      clientSideID: window.ENV.REACT_APP_LAUNCHDARKLY_KEY,
      context: buildLaunchDarklyContext(profile),
    }),
  )

  return pipe(
    value,
    O.fromNullable,
    O.match(
      () => null,
      (Provider) => <Provider>{children}</Provider>,
    ),
  )
}

export default LaunchDarklyProvider
