import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import React from 'react'
import { flow, identity, pipe } from 'fp-ts/function'
import { useDispatch, useSelector } from 'react-redux'

import { closeNotification, isLink, Notification, NotificationWithLink } from '../../store/notification/slicer'
import { RootState } from '../../store'

import LinkNotification from './LinkNotification'
import TextNotification from './TextNotification'

const Snackbar: React.FC = () => {
  const dispatch = useDispatch()
  const { notification } = useSelector((state: RootState) => state.notifications)

  const onClose = React.useCallback(() => {
    dispatch(closeNotification())
  }, [dispatch])

  return pipe(
    notification,
    O.match(
      () => null,
      flow(
        E.fromPredicate(isLink, identity),
        E.match(
          (n: Notification) => <TextNotification message={n.message} onClose={onClose} />,
          (n: NotificationWithLink) => <LinkNotification message={n.message} route={n.route} onClose={onClose} />,
        ),
      ),
    ),
  )
}

export default Snackbar
