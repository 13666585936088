import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

/* -------------------------------------------------------------------------------------------------
 * CustomStyles
 * -----------------------------------------------------------------------------------------------*/

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
}))

/* -------------------------------------------------------------------------------------------------
 * LoadingCircular - mainly used in <Suspense />
 * -----------------------------------------------------------------------------------------------*/

export const LoadingCircular = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  )
}
