import _ from 'lodash'
import React from 'react'
import { User } from '../../userprofile/types/user'

export type AuthContextType = {
  user: Maybe<User>
  logout: () => void
}

const authContext = React.createContext<AuthContextType>({
  user: null,
  logout: _.noop,
})

authContext.displayName = 'AuthContext'

export { authContext as AuthContext }
