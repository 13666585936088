import React, { createContext, useState } from 'react'
import { DiscardChangesDialogProps } from '../../components/DiscardChangesDialog'
import { noop } from 'lodash'

interface DiscardChangesContextType {
  DiscardChangesComponent: React.FC<DiscardChangesDialogProps> | null
  setDiscardChangesComponent: React.Dispatch<(() => React.FC<DiscardChangesDialogProps>) | null>
}

export const DiscardChangesContext = createContext<DiscardChangesContextType>({
  DiscardChangesComponent: null,
  setDiscardChangesComponent: noop,
})

const DiscardChangesProvider: React.FC = ({ children }) => {
  const [DiscardChangesComponent, setDiscardChangesComponent] =
    useState<DiscardChangesContextType['DiscardChangesComponent']>(null)

  return (
    <DiscardChangesContext.Provider value={{ DiscardChangesComponent, setDiscardChangesComponent }}>
      {children}
    </DiscardChangesContext.Provider>
  )
}

export default DiscardChangesProvider
