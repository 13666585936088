import { useGetRegionsQuery } from '../graphql/gateway/queries/GetRegions.generated'
import { Region } from '../graphql/gateway/types'

const useGetRegions = (countryId: string) => {
  const { data, loading, error } = useGetRegionsQuery({
    fetchPolicy: 'cache-first',
    skip: !countryId,
    variables: {
      sorting: { column: 'name' },
      filter: [
        { key: 'countryId', type: 'EQ', value: countryId },
        { type: 'EQ', key: 'testRegion', value: 'false' },
      ],
    },
  })

  return {
    data: data?.regions.entities as Region[],
    loading,
    error,
  }
}

export default useGetRegions
