import { unsafeCoerce } from 'fp-ts/lib/function'
import { GetMeQuery } from '../../graphql/api/queries/GetMe.generated'
import routes from '../../routes'
import { UserProfile } from '../../userprofile/types/preferences'

export function extractUserProfile({ me }: GetMeQuery): UserProfile {
  let redirectRoute = routes.INITIAL_ROUTE

  if (!me.isOnboarded) {
    redirectRoute = routes.onboarding
  }

  if (me.isBlocked || !me.isAuthorized) {
    redirectRoute = routes.pendingAuthorization
  }

  return {
    userId: me.userId,
    name: me.name,
    email: me.email,
    phone: me.phone,
    group: unsafeCoerce(me.group),
    role: me.role,
    permissions: me.permissions,
    datePattern: me.datePattern,

    density: unsafeCoerce(me.density),
    weight: unsafeCoerce(me.weight),
    length: unsafeCoerce(me.length),
    area: unsafeCoerce(me.area),
    volume: unsafeCoerce(me.volume),
    areaDensity: unsafeCoerce(me.areaDensity),

    country: unsafeCoerce(me.country),
    region: unsafeCoerce(me.region),

    isAdmin: me.isAdmin,
    isAuthorized: me.isAuthorized,
    isBlocked: me.isBlocked,
    isOnboarded: me.isOnboarded,

    shouldDisplaySidebarNav: me.isAuthorized && me.isOnboarded && !me.isBlocked,
    shouldDisplaySignupRoutes: me.isBlocked || !me.isOnboarded || !me.isAuthorized,
    redirectRoute: redirectRoute,
  }
}
