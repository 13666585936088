import { TFunction } from 'i18next'
import { slugTranslationKey } from './slugs'

interface CountryType {
  name?: string | null | undefined
}

export const getCountryTranslationKeys = (name: string) => `countries.${slugTranslationKey(name)}`
export const getCountryName = (country: CountryType | null | undefined, t: TFunction): string => {
  if (!country) {
    return ''
  }

  const { name } = country

  if (!name) {
    return ''
  }

  return t(getCountryTranslationKeys(name))
}
