import React from 'react'
import * as MUI from '@material-ui/core'

export type PageHeadingProps = {
  readonly label: string
  readonly variant: 'standard' | 'form'
}

const PageTitle: React.FC<PageHeadingProps> = (props) => {
  const { variant, label } = props

  switch (variant) {
    case 'standard':
      return (
        <MUI.Box maxWidth={1440}>
          <MUI.Typography variant={'h1'} color="primary">
            {label}
          </MUI.Typography>
        </MUI.Box>
      )
    case 'form':
      return (
        <MUI.Box
          maxWidth={600}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          marginY={0}
          marginX={'auto'}
        >
          <MUI.Typography variant={'h1'} color="primary" align="center">
            {label}
          </MUI.Typography>
        </MUI.Box>
      )
  }
}

export default PageTitle
