import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MessageStatus = 'active' | 'loading' | 'failed' | 'idle'
export type Message = {
  message: string
  status: MessageStatus
}

/**
 * @internal This function is only one
 * to example purpose.
 */
export const fetchMessage = () => {
  return new Promise<Message>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'Setup is successfully!!!',
          status: 'active',
        }),
      500,
    ),
  )
}

/**
 * @internal Not use this message slice,
 * it's only to example purpose.
 */
export const example = createSlice({
  name: 'example',
  initialState: {
    message: '',
    status: 'idle',
  },
  reducers: {
    setMessage: (state, action: PayloadAction<Message>) => {
      state.message = action.payload.message
      state.status = action.payload.status
    },
    setMessageContent: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setMessageStatus: (state, action: PayloadAction<MessageStatus>) => {
      state.status = action.payload
    },
  },
})
