import { AuthenticationState } from './types'

export class AuthenticationError extends Error {
  private constructor(public readonly state: AuthenticationState, public readonly reason: string) {
    super(`Authentication error: ${reason}`)
  }

  static whenUserIsNotLogged(): AuthenticationError {
    return new AuthenticationError('noLogged', 'User is not logged')
  }

  static whenUserTokenIsExpired(): AuthenticationError {
    return new AuthenticationError('tokenExpired', 'User has a expired token')
  }
}
