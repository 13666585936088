import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { styled } from '@material-ui/core/styles'

const ErrorIconComp = styled(ErrorIcon)({
  color: 'rgba(0, 62, 126, 0.3)',
  height: 64,
  width: 64,
})

const SorryComp = styled('span')({
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

interface LoadingErrorProps {
  errorText?: string
}

const LoadingError: React.FC<LoadingErrorProps> = ({ errorText }: LoadingErrorProps) => {
  const { t } = useTranslation()
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item>
        <ErrorIconComp />
      </Grid>
      <Grid item>
        <Typography color="textPrimary" variant="h4">
          <SorryComp>{t('sorry')}!</SorryComp>
        </Typography>
        <Typography color="textPrimary" variant="h6">
          {errorText || t('fetching-data-failed')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default LoadingError
