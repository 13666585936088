import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import routes from '../routes'

type ProtectedComponent = {
  condition: boolean
  path: string
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const ProtectedRoute: React.ComponentType<ProtectedComponent> = ({ condition, path, component }) => {
  return condition ? <Route path={path} component={component} /> : <Redirect to={routes.INITIAL_ROUTE} />
}

export default ProtectedRoute
