const analyses = '/analyses'
const combos = '/combos'
const programs = '/programs'
const clients = '/clients'

type RoutingMap = {
  [T in keyof typeof routesList]: (typeof routesList)[T]
} & {
  INITIAL_ROUTE: string
}

const routesList = {
  analyses,
  analysesAdd: `${analyses}/add`,
  analysesEdit: `${analyses}/edit/:analysisId/:clientId?/:farmId?/:fieldId?`,
  analysesUpload: `${analyses}/upload`,
  clients,
  clientsAdd: `${clients}/add`,
  clientsAddRecommendation: `${clients}/:clientId/farm/:farmId/field/:fieldId/add`,
  clientsUploadAnalysis: `${clients}/uploadAnalysis/:fieldId/:clientId/:farmId`,
  clientsEdit: `${clients}/:clientId/edit`,
  clientPage: `${clients}/:clientId`,
  clientsCreateFarm: `${clients}/farm/add`,
  farmPageAdd: `${clients}/:clientId/farms/add`,
  farmPageEdit: `${clients}/:clientId/farms/:farmId/edit`,
  farmPage: `${clients}/:clientId/farms/:farmId`,
  fieldPageCreate: `${clients}/:clientId/farm/:farmId/fields/add`,
  fieldPageEdit: `${clients}/:clientId/farms/:farmId/fields/:fieldId/edit`,
  fieldPage: `${clients}/:clientId/farms/:farmId/fields/:fieldId/:tab(recommendations|analyses)?`,
  fieldPageProgramDuplicate: `${clients}/:clientId/farms/:farmId/fields/:fieldId/programs/:programId`,
  fieldPageProgramCreate: `${clients}/:clientId/farms/:farmId/fields/:fieldId/programs/add`,
  combos,
  comboAdd: `${combos}/add`,
  comboProductsRelative: `/products`,
  comboReviewRelative: `/review`,
  comboCopy: `${combos}/copy/:comboId`,
  comboEdit: `${combos}/edit/:comboId`,
  comboShare: `${combos}/share/:comboId`,
  programs,
  programAdd: `${programs}/add`,
  programAddCH: `${programs}/add/:clientId/:farmId/:fieldId`,
  programAddComboAdd: `${programs}/add/combo/add`,
  programAddComboEdit: `${programs}/add/combo/edit/:comboId`,
  programAddAnalysisAdd: `${programs}/add/analysis/add`,
  programAddAnalysisSelect: `${programs}/add/analysis/select`,
  programAddAnalysisUpload: `${programs}/add/analysis/upload`,
  programCopy: `${programs}/copy/:programId/:fieldId?`,
  programCopyComboAdd: `${programs}/copy/:programId/combo/add`,
  programCopyComboEdit: `${programs}/copy/:programId/combo/edit/:comboId`,
  programCopyAnalysisAdd: `${programs}/copy/:programId/analysis/add`,
  programCopyAnalysisSelect: `${programs}/copy/:programId/analysis/select`,
  programCopyAnalysisUpload: `${programs}/copy/:programId/analysis/upload`,
  programShow: `${programs}/:programId`,
  recommendationShow: `${programs}/:programId/recommendation/:recommendationId/:clientId?/:farmId?/:fieldId?`,
  recommendationShare: `${programs}/:programId/recommendation/:recId/share`,
  recommendationPrint: `${programs}/:programId/recommendation/:recommendationId/print`,
  recommendationShareOld: `${programs}/share/:recId`,
  test: '/test',
  usersettings: '/usersettings',
  users: '/users',
  userRoles: '/users/roles',
  userPermissionMap: '/users/permissions',
  maintenance: '/maintenance',
  publish: `/recommendation/publish/:reportId`,

  // BACK OFFICE
  backOffice: '/back-office',
  backOfficeMenu: '/back-office/menu',
  backOfficeStandardProducts: '/back-office/standard-products',
  backOfficeStandardProductsForm: '/back-office/standard-products/form',

  /** signup-specific routes **/
  completeProfile: `/signup/complete-profile`,
  pendingAuthorization: `/signup/pending-authorization`,
  onboarding: `/signup/onboarding`,
}

const routes: RoutingMap = {
  ...routesList,
  INITIAL_ROUTE: routesList.programs,
}

export const signupRoutes = [routes.completeProfile, routes.pendingAuthorization, routes.onboarding]

export default routes
