import React from 'react'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { SIDEBAR_NAVIGATION_WIDTH } from '../Navigation/constants'

type TextNotificationProps = {
  readonly message: string
  readonly onClose: () => void
}

const TextNotification: React.FC<TextNotificationProps> = ({ message, onClose }) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      style={{ marginLeft: SIDEBAR_NAVIGATION_WIDTH }}
      onClose={onClose}
    >
      <SnackbarContent
        style={{ background: '#232735' }}
        message={message}
        action={
          <IconButton color="inherit" size="small" aria-label="close" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default TextNotification
