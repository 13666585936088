import * as Types from '../types.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type GetRegionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Array<Types.GenericFilter> | Types.GenericFilter>;
  sorting?: Types.InputMaybe<Array<Types.Sorting> | Types.Sorting>;
  paging?: Types.InputMaybe<Types.Pagination>;
}>;


export type GetRegionsQuery = { __typename?: 'Query', regions: { __typename?: 'ManyRegionResult', entities: Array<{ __typename?: 'Region', id: string, name: string }> } };


export const GetRegionsDocument = gql`
    query GetRegions($filter: [GenericFilter!], $sorting: [Sorting!], $paging: Pagination) {
  regions(filter: $filter, sorting: $sorting, paging: $paging) {
    entities {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;