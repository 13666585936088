import * as O from 'fp-ts/Option'
import React from 'react'
import { useGetMeQuery } from '../../graphql/api/queries/GetMe.generated'
import { LoadingCircular } from '../../components/LoadingCircular'
import LoadingError from '../../components/LoadingError'
import { UserProfile } from '../../userprofile/types/preferences'
import { UserPreferencesContext } from './UserPreferencesContext'
import { User } from '../../userprofile/types/user'
import { useDispatch } from 'react-redux'
import { ItemsPerPageOptions, setItemsPerPage } from '../../store/preferences/slicer'
import { monitorUserLoggedIn, trackUserLoggedIn } from '../../userprofile/tracking'
import { flow } from 'fp-ts/lib/function'
import ErrorState from '../../components/ErrorState/ErrorState'
import { extractUserProfile } from './userPreferences'

type PreferencesProviderProps = {
  readonly isAuthenticated: boolean
  readonly user: User
}

export const UserPreferencesProvider: React.FC<PreferencesProviderProps> = (props) => {
  const { user, isAuthenticated, children } = props

  const [error, setError] = React.useState<O.Option<Error>>(O.none)
  const [userProfile, setUserProfile] = React.useState<O.Option<UserProfile>>(O.none)

  const { loading, refetch } = useGetMeQuery({
    skip: !isAuthenticated,
    onError: flow(O.some, setError),
    onCompleted: flow(extractUserProfile, O.some, setUserProfile),
  })

  const dispatch = useDispatch()
  const itemsPerPageValueFromUser = localStorage.getItem('itemsPerPage')
  const defaultItemsPerPage = itemsPerPageValueFromUser ? parseInt(itemsPerPageValueFromUser, 10) : 20

  dispatch(setItemsPerPage(defaultItemsPerPage as ItemsPerPageOptions))

  React.useEffect(
    function () {
      if (O.isSome(userProfile)) {
        trackUserLoggedIn(
          user,
          userProfile.value.userId,
          userProfile.value.email,
          userProfile.value.name,
          userProfile.value.country.name,
          userProfile.value.region.name,
        ).then(() => monitorUserLoggedIn(user, userProfile.value.role))
      }
    },
    [user, userProfile],
  )

  if (loading) {
    return <LoadingCircular />
  }

  if (O.isSome(error)) {
    return <LoadingError errorText={error.value.message} />
  }

  if (O.isNone(userProfile)) {
    return <ErrorState onRefetch={refetch} />
  }

  return <UserPreferencesContext.Provider value={userProfile.value}>{children}</UserPreferencesContext.Provider>
}
