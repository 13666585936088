import React, { useContext } from 'react'
import { DiscardChangesContext } from '../contexts/discard/DiscardChangesProvider'
import NavigationPrompt from 'react-router-navigation-prompt'

/* -------------------------------------------------------------------------------------------------
 * CustomNavigationPrompt
 * -----------------------------------------------------------------------------------------------*/

export const CustomNavigationPrompt: React.FC = () => {
  const { DiscardChangesComponent } = useContext(DiscardChangesContext)
  return (
    <NavigationPrompt when={!!DiscardChangesComponent}>
      {({ onCancel, onConfirm }) =>
        DiscardChangesComponent && <DiscardChangesComponent onCancel={onCancel} onConfirm={onConfirm} />
      }
    </NavigationPrompt>
  )
}

export default CustomNavigationPrompt
