import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnalysisFormData } from '../../pages/Analyses/components/AnalysisSheet'

export const analysesSlice = createSlice({
  name: 'analyses',
  initialState: {
    uploaded: [] as AnalysisFormData[],
  },
  reducers: {
    setUploadedAnalyses: (state, action: PayloadAction<AnalysisFormData[]>) => {
      state.uploaded = action.payload
    },
  },
})

export const { setUploadedAnalyses } = analysesSlice.actions
