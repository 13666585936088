import { AuthenticationError } from './AuthenticationError'
import { AuthenticationHandlerMap } from './types'

export const matchAuthenticationErrors = (handlers: AuthenticationHandlerMap) => {
  return (error: AuthenticationError) => {
    switch (error.state) {
      case 'tokenExpired':
        return handlers.tokenExpired(error)
      case 'noLogged':
        return handlers.noLogged(error)
      default:
        return handlers.unexpected(error)
    }
  }
}
