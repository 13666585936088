import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MaterialSelect,
  SelectProps as MaterialSelectProps,
  Typography,
} from '@material-ui/core'

export interface SelectExternalProps extends MaterialSelectProps {
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  value?: string | string[]
  errorMessage?: string | boolean
}

interface SelectProps extends SelectExternalProps {
  children: React.ReactNode
  error?: boolean
  errorMessage?: string | undefined
  id: string
  label?: React.ReactNode
  name: string
  noValue?: string | null
}

const fontWeightStyle = { color: '#979797', fontWeight: 500 }

const Placeholder: React.FC<{ text?: string }> = ({ text }) => {
  const { t } = useTranslation()
  return (
    <Typography component="span" style={fontWeightStyle} variant="inherit">
      {text || t('actions.choose')}
    </Typography>
  )
}

export const renderPlaceholder = (): React.ReactNode => {
  return <Placeholder />
}

export const renderCustomPlaceholder = (text?: string): React.ReactNode => {
  return <Placeholder text={text} />
}

const Select: React.FC<SelectProps> = ({
  children,
  disabled,
  required,
  id,
  label,
  name,
  onChange,
  noValue = '',
  value,
  errorMessage,
  ...props
}: SelectProps) => {
  return (
    <FormControl disabled={disabled} required={required} error={props.error}>
      {label && <InputLabel id={id}>{label}</InputLabel>}
      <MaterialSelect
        disabled={disabled}
        displayEmpty
        labelId={id}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        name={name}
        onChange={onChange}
        renderValue={value === noValue ? renderPlaceholder : undefined}
        value={value}
        {...props}
      >
        {children}
      </MaterialSelect>

      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default Select
