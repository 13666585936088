import React from 'react'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import { flow, pipe, unsafeCoerce } from 'fp-ts/function'
import { CountriesContext } from './CountriesContext'
import { useGetCountriesQuery } from '../../graphql/api/queries/GetCountries.generated'

import LoadingError from '../../components/LoadingError'
import type { Country, GetCountriesQuery } from '../../graphql/api/types'
import { LoadingCircular } from '../../components/LoadingCircular'

function handleQueryData({ countries }: GetCountriesQuery): ReadonlyArray<Country> {
  return pipe(countries, RA.map(unsafeCoerce<any, Country>))
}

const CountriesContextProvider: React.FC = ({ children }) => {
  const [error, setError] = React.useState<O.Option<Error>>(O.none)
  const [countries, setCountries] = React.useState<ReadonlyArray<Country>>([])

  const { loading } = useGetCountriesQuery({
    fetchPolicy: 'cache-first',
    onError: flow(O.some, setError),
    onCompleted: flow(handleQueryData, setCountries),
  })

  if (loading) {
    return <LoadingCircular />
  }

  if (O.isSome(error)) {
    return <LoadingError errorText={error.value.message} />
  }

  return <CountriesContext.Provider value={countries}>{children}</CountriesContext.Provider>
}

export default CountriesContextProvider
