import _ from 'lodash'
import { User } from '../../userprofile/types/user'
import { EventPayload, Monitoring } from './types'

const user = (user: User) => console.debug('user', _.omit(user, 'token'))
const page = (name: string) => console.debug('page', name)
const meta = (key: string, value: string) => console.debug(key, value)
const trace = (traceId: string) => console.debug('traceId', traceId)

const event = (name: string, payload: EventPayload) => {
  return console.debug(name, {
    duration: new Date().getTime() - payload.start,
    componentStack: payload.component,
    error: payload.error,
    meta: payload.meta,
  })
}
const error = (error: Error, payload: Record<string, string | number | boolean>) => {
  return console.error(error.message, payload)
}

export const io: Monitoring = {
  page,
  meta,
  event,
  user,
  error,
  trace,
}
