import React from 'react'
import { useTranslation } from 'react-i18next'
import { increment } from 'fp-ts/function'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ServerErrorStateIcon } from '../../assets/icons/serverErrorState.svg'
import { ReactComponent as ServerBackSoonIcon } from '../../assets/icons/serverBackSoon.svg'
import { LoadingCircular } from '../LoadingCircular'

const useStyles = makeStyles((theme) => ({
  text: {
    maxWidth: '272px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#505565',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  button: {
    width: '240px',
    borderRadius: '4px',
  },
}))

type ErrorStateProps = {
  readonly noRefetch?: boolean
  readonly onRefetch: () => Promise<unknown>
}

const ErrorState: React.FC<ErrorStateProps> = (props) => {
  const { t } = useTranslation()
  const { onRefetch, noRefetch } = props
  const classes = useStyles()

  const [retries, setRetries] = React.useState<number>(0)
  const [loading, setLoading] = React.useState<boolean>(false)

  const doRefetch = React.useCallback(async () => {
    if (loading) {
      return
    }

    setLoading(true)
    await onRefetch()
    setRetries(increment)
    setLoading(false)
  }, [loading, onRefetch])

  return (
    <Box mt={8}>
      {retries >= 5 ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <ServerBackSoonIcon />
          <Typography color="textPrimary" variant="h2">
            {t('errors.back-soon')}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary" className={classes.text}>
            {t('errors.server-error-message')}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          {loading ? <LoadingCircular /> : <ServerErrorStateIcon />}
          <Typography color="textPrimary" variant="h2">
            {t('errors.server-error')}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary" className={classes.text}>
            {t('errors.server-error-message')}
          </Typography>
          {!noRefetch ? (
            <Button
              type="button"
              variant="contained"
              className={classes.button}
              disabled={loading}
              endIcon={loading && <CircularProgress color={'secondary'} size={14} />}
              onClick={doRefetch}
            >
              {t('errors.try-again')}
            </Button>
          ) : null}
        </Box>
      )}
    </Box>
  )
}

export default ErrorState
