import React, { createContext, useState } from 'react'
import { noop } from 'lodash'

interface ISettingsContext {
  open: boolean
  toggleOpen: () => void
}

export const SettingsContext = createContext<ISettingsContext>({
  open: false,
  toggleOpen: noop,
})

SettingsContext.displayName = 'SettingsContext'

const SettingsProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return <SettingsContext.Provider value={{ open, toggleOpen }}>{children}</SettingsContext.Provider>
}

export default SettingsProvider
