const fontWeightBold = {
  fontWeight: 700,
}

type CSSKeyAndProperty = Record<string, any>
type ColorDefinitions = Record<string, CSSKeyAndProperty>

//@todo: extract all colors used in the application and put here. Later, in the theme.ts
export const colors: ColorDefinitions = {
  text: {
    primary: '#232735',
    secondary: '#505565',
    tertiary: '#8b90a0',
    white: '#FFFFFF',
  },
  blue: {
    darker: '#133F7A',
    dark: '#003E7E',
    default: '#1370BC',
  },
}

export const typography = {
  htmlFontSize: 14,
  fontSize: 14,

  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  allVariants: {
    letterSpacing: -0.02,
    color: colors.text.primary,
  },
  fontFamily: ['Noto Sans', 'sans-serif'].join(','),
  h1: {
    fontSize: 24,
    lineHeight: '40px',
    ...fontWeightBold,
  },
  h2: {
    fontSize: 20,
    lineHeight: '28px',
    ...fontWeightBold,
  },
  h3: {
    fontSize: 16,
    lineHeight: '24px',
    ...fontWeightBold,
  },
  h4: {
    fontSize: 14,
    ...fontWeightBold,
  },
  h5: {
    fontSize: 14,
    ...fontWeightBold,
  },
  h6: {
    fontSize: 14,
  },
  body1: {
    fontSize: '14px',
  },
  body2: {
    fontSize: '14px',
  },
  subtitle2: {
    fontWeight: 400,
  },
  subtitle1: {
    fontWeight: 500,
  },
}
